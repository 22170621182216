const darkThemes = new Set([
  'Charcoal Dark',
  'Bluescape Strong',
  'Bluescape Dark',
]);

export type Theme = 'White' |
  'Charcoal Bleach' |
  'Charcoal Dark' |
  'Bluescape Bleach' |
  'Bluescape Pale' |
  'Bluescape Strong' |
  'Bluescape Dark' |
  'Grape Bleach' |
  'Grape Pale' |
  'Peony Bleach' |
  'Peony Pale' |
  'Sherbet Bleach' |
  'Sherbet Pale' |
  'Tangerine Bleach' |
  'Tangerine Pale' |
  'Turtle Bleach' |
  'Turtle Pale';

export type Replace<T extends string, S extends string, D extends string,
  A extends string = ''> = T extends `${infer L}${S}${infer R}` ?
  Replace<R, S, D, `${A}${L}${D}`> : `${A}${T}`

export type ProcessedTheme<T = unknown> = `theme-${Replace<Lowercase<(Theme & T)>, ' ', '-'>}`;

export const isDark = (theme: Theme) => darkThemes.has(theme);

export type ThemeFunc = (<T>(theme: Theme) => ProcessedTheme<T>) | ((theme: undefined) => '');

// function signature for function that returns an empty string if the theme is
// undefined and ProcessedTheme if it is defined
// while keeping the typescript literal all the way through using infer
export function normalizeThemeClassName<T extends Theme | undefined>(contentfulThemeName: T): T extends Theme ? ProcessedTheme<T> : '' {
  if (contentfulThemeName === undefined) {
    return '' as T extends Theme ? never : '';
  }

  return `theme-${contentfulThemeName.toLowerCase().replace(' ', '-')}` as T extends Theme ? ProcessedTheme<T> : never;
}
