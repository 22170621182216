import {
  useEffect, useState, useRef,
} from 'react';
import Image from 'next/image';
import styled, { keyframes } from 'styled-components';
import { AutoButton } from './Button';
import ExpandableMenu from './ExpandableMenu';
import MenuFlyoutBox from './MenuFlyoutBox';
import { CFImage, CFLink } from '../../lib/types';
import Link, { AutoLink } from './Link';
import AnnouncementBanner, { AnnouncementBannerProps } from './AnnouncementBanner';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export type MenuPageLinkItem = {
  title: string,
  sections: undefined,
  page: {
    slug: string
  }
}

export type MenuDirectLinkItem = {
  sections: undefined,
  page: undefined,
  title: string,
  url: string
}

export type NavigationItem = {
  title: string,
  link?: CFLink,
  children: NavigationItem[]
  icon?: CFImage
}

type Props = {
  items?: Array<NavigationItem>
  secondaryItems?: Array<NavigationItem>
  darkTheme?: boolean
  callToAction?: CFLink
  transparent?: boolean
  copyrightSuffix: string;
  address: string;
  termsLinks: CFLink[];
  announcementBanner?: AnnouncementBannerProps;
}

const Chevron = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L9 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ExpandIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 18C2 17.4477 2.44772 17 3 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18Z" fill="currentColor" />
  </svg>
);

const CloseIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#2E363D" />
  </svg>
);

const NavbarContainer = styled.div`
  box-sizing: border-box;
  max-width: ${({ theme }) => theme.layout.maxWidth};
  background: white;
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 70px;
  color: var(--color-charcoal-v100);
  border: 1px solid var(--color-charcoal-v10);
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  font-size: 16px;
  border-radius: 16px;
  top: 0;
  box-shadow: ${(props) => props.theme.shadows.hard};
  &.transparent {
    background: none;
    border: none;
    box-shadow: none;
  }
  &.dark-theme {
    color: white;
  }
  &.fixed {
    position: fixed;
    background: white !important;
    z-index: 10000;
    border-radius: 0;
  }
  @media screen and (max-width: 1080px) {
    padding: 0 25px;
  }
`;

const Logo = styled.a`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.1rem;
  margin-right: 16px;
`;

type PositionedProps = {
  translateX: number
}

const NavbarOuterContainer = styled.nav`
  z-index: 100;

  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column-reverse;
  }
`;

const Positioned = styled.div<PositionedProps>`
  margin-top: 8px;
  animation: ${fadeIn} ${(props) => props.theme.transitions.short};
  position: absolute;
  transform: translateX(${(props) => props.translateX - 25}px);
  transition: ${(props) => props.theme.transitions.short};
  z-index: 1000;
`;

const MenuItems = styled.div`
  display: flex;
  grid-auto-flow: column;
  gap: 24px;
  height: 100%;
  align-items: center;
  @media (max-device-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

type MenuItemProps = {
  selected?: boolean
}

const MenuItem = styled.div<MenuItemProps>`
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;

  a, .link, button {
    transition: ${(props) => props.theme.transitions.short} color;
    text-decoration: none;
    color: inherit;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--theme-primary-button-background);
    }
    &:active {
      color: var(--theme-primary-button-background-hover);
    }
  }
  &.selected {
    color: var(--theme-primary-button-background);
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    transition: transform ${(props) => props.theme.transitions.short};
    margin-left: 8px;
    margin-bottom: 2px;
  }
`;

const PrimarySection = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 24px;
  height: 100%;
`;

const SecondarySection = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 24px;
`;

const Expand = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  color: inherit;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
  &.dark-theme {
    color: white;
  }
`;

export const SkipLink = styled.a`
  position: absolute;
  translate: 0 -100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  padding: 10px 12px;
  font-size: 16px;
  background-color: var(--theme-primary-button-background);
  font-weight: 600;
  color: var(--theme-primary-button-color);
  border-radius: 8px;
  z-index: 1000;
  &:focus {
    opacity: 1;
    pointer-events: unset;
    translate: 16px 16px;
  }
`;

const CallToAction = styled.div`
  @media (max-width: 330px) {
    display: none;
  }
`;

const MenuButton = styled.button`
  cursor: pointer;
  background: none;
  color: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  font: inherit;
`;

const Navbar = ({
  items = [],
  secondaryItems = [],
  callToAction,
  transparent = false,
  darkTheme = false,
  announcementBanner,
} : Props) => {
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const [translateMenuBox, setTranslateMenuBox] = useState(0);
  const [selectedItem, setSelectedItem] = useState<NavigationItem | null>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const listener = () => {
      setSelectedItem(null);
      setMobileMenuExpanded(false);
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  });
  useEffect(() => {
    const listener = (ev: MouseEvent) => {
      if (ev.target instanceof Element) {
        if (navbarRef.current?.contains(ev.target)) return;
        setSelectedItem(null);
      }
    };
    window.addEventListener('click', listener);
    return () => {
      window.removeEventListener('click', listener);
    };
  });

  const handleFlyoutClick = (e: React.MouseEvent<HTMLButtonElement>, item: NavigationItem) => {
    e.preventDefault();
    if (e.currentTarget instanceof Element) {
      const targetX = e.currentTarget.getBoundingClientRect().x;
      const navbarX = navbarRef.current?.getBoundingClientRect().x ?? 0;
      const offset = targetX - navbarX;
      setTranslateMenuBox(offset);
      if (item === selectedItem) {
        setSelectedItem(null);
      } else {
        setSelectedItem(item);
      }
    }
  };
  return (
    <NavbarOuterContainer ref={navbarRef}>
      <div>
        <SkipLink href="#content">Skip to main content</SkipLink>
        <NavbarContainer className={`${mobileMenuExpanded ? 'fixed' : ''} ${transparent ? 'transparent' : ''} ${darkTheme ? 'dark-theme' : ''}`}>
          <PrimarySection>
            <Link as={Logo} href="/" passHref>
              <Image priority width="144" height="24" src={(darkTheme && !mobileMenuExpanded) ? '/images/logo-light.svg' : '/images/logo.svg'} alt="Bluescape logo" />
            </Link>
            <MenuItems>
              {items.map((item) => {
                if (item.children.length) {
                  return (
                    <MenuItem className={item === selectedItem ? 'selected' : ''} key={item.title}>
                      <MenuButton type="button" onClick={(e) => handleFlyoutClick(e, item)}>
                        {item.title}
                        <Chevron />
                      </MenuButton>
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={item.title}>
                    {item.link && <AutoLink {...item.link} />}
                  </MenuItem>
                );
              })}
            </MenuItems>
          </PrimarySection>
          <SecondarySection>
            <MenuItems>
              {secondaryItems.map((item) => {
                if (item.children.length) {
                  return <div />;
                }
                return (
                  <MenuItem key={item.title}>
                    {item.link && <AutoLink {...item.link} />}
                  </MenuItem>
                );
              })}
            </MenuItems>
            {callToAction && !mobileMenuExpanded && (
              <CallToAction>
                <AutoButton small {...callToAction} />
              </CallToAction>
            )}
            <Expand className={`${darkTheme ? 'dark-theme' : ''}`} aria-label="Toggle menu" onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)}>
              {mobileMenuExpanded ? (
                <CloseIcon />
              ) : (
                <ExpandIcon />
              )}
            </Expand>
          </SecondarySection>
        </NavbarContainer>
        {selectedItem && (
          <Positioned translateX={translateMenuBox}>
            <MenuFlyoutBox {...selectedItem} />
          </Positioned>
        )}
        {mobileMenuExpanded && (
          <ExpandableMenu callToAction={callToAction} items={[...items, ...secondaryItems]} />
        )}
        <div id="content" />
      </div>
      {announcementBanner && <AnnouncementBanner {...announcementBanner} />}
    </NavbarOuterContainer>
  );
};

export default Navbar;
