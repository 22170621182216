import { gql } from '@apollo/client';
import Image from 'next/image';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CFImage, CFLink, WithId } from '../../lib/types';
import { normalizeThemeClassName, Theme } from '../../styles/themes';
import ArrowLink from './ArrowLink';
import { AutoLink } from './Link';

export type AnnouncementBannerProps = WithId<{
  title: string;
  text: string;
  image?: CFImage;
  theme: Theme;
  link?: CFLink;
  isIcon?: boolean;
}>;

export const announcementBannerFragment = gql`
  fragment announcementBannerFragment on AnnouncementBanner {
    sys { id }
    title
    text
    image { ...image }
    isIcon
    theme
    link { ...link }
  }
`;

const Wrapper = styled.div<{isIcon?: boolean}>`
  &, * {
    box-sizing: border-box;
  }
  max-width: calc(${({ theme }) => theme.layout.maxWidth} - 64px);
  margin: 0 32px;
  display: flex;
  /* height: 88px; */
  overflow: hidden;
  width: 100%;
  // There's no color matching the darker themes for grape etc, so we use the border hover color
  border: 1px solid var(--theme-border-color-hover);
  border-radius: 16px;
  gap: 24px;
  background-color: var(--theme-border-color);
  color: var(--theme-text-color);
  position: relative;
  box-shadow: ${({ theme }) => theme.shadows.hard};
  margin-bottom: 24px;

  > img {
    max-width: 144px;
    object-fit: cover;
    height: auto;

    ${({ isIcon }) => isIcon && css`
      height: 100%;
      margin-left: 24px;
      align-self: center;
    `}
  }

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    font-size: 15px;
  }

  @media (max-width: 1080px) {
    margin: 0 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 0;
    border-radius: 0;
    gap: 16px;
    border: none;
    border-bottom: 1px solid var(--theme-border-color-hover);
    box-shadow: none;

    h2, p {
      font-size: 16px;
    }

    > img {
      width: 32px;

      ${({ isIcon }) => !isIcon && css`
        display: none;
      `}
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 21px 0;

`;

const CloseButton = styled.button`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin: 24px;
  padding: 4px;
  margin-left: auto;
`;

const CustomArrowLink = styled(ArrowLink)`
  display: inline-block;
  margin-inline-start: 8px;
  color: var(--theme-slider-arrow-active);

  &:hover {
    color: var(--theme-slider-button-active);
  }
`;

const OuterWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const getClosedBanners = () => {
  const closedBanners = localStorage.getItem('closed-banners');
  const banners = closedBanners ? JSON.parse(closedBanners) as {id: string, date: string}[] : [];

  // Remove banners that are older than 5 days
  const now = new Date();
  const fiveDaysAgo = new Date(now);
  fiveDaysAgo.setDate(now.getDate() - 5);
  const filteredBanners = banners.filter((banner) => new Date(banner.date) > fiveDaysAgo);

  localStorage.setItem('closed-banners', JSON.stringify(filteredBanners));
  return filteredBanners;
};

export const addClosedBanner = (id: string) => {
  const closedBanners = getClosedBanners();
  closedBanners.push({ id, date: new Date().toISOString() });
  localStorage.setItem('closed-banners', JSON.stringify(closedBanners));
};

const Cross = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    width="12"
  >
    <path
      fill="#2E363D"
      d="M.41.41a.83.83 0 0 1 1.18 0L6 4.82l4.41-4.4a.83.83 0 1 1 1.18 1.17L7.18 6l4.4 4.41a.83.83 0 1 1-1.17 1.18L6 7.18l-4.41 4.4A.83.83 0 0 1 .4 10.42L4.82 6 .42 1.59A.83.83 0 0 1 .41.4Z"
    />
  </svg>
);

const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  sys: { id }, text, theme, title, image, link, isIcon,
}) => {
  // If the banner is replaced with a new one, we want to show it again
  const [showBanner, setShowBanner] = React.useState(false);

  useEffect(() => {
    if (getClosedBanners().find((banner) => banner.id === id) === undefined) {
      setShowBanner(true);
    }
  }, [id, setShowBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <OuterWrapper>
      <Wrapper isIcon={isIcon} className={normalizeThemeClassName(theme)}>
        {image && (
          <Image
            src={image.url}
            alt={image.title}
            width={image.width}
            height={image.height}
          />
        )}
        <Content>
          <h2>{title}</h2>
          <p>
            {text}
            {link && <AutoLink as={CustomArrowLink} {...link} />}
          </p>
        </Content>
        <CloseButton
          type="button"
          aria-label="Hide banner"
          onClick={() => {
            addClosedBanner(id);
            setShowBanner(false);
          }}
        >
          <Cross />
        </CloseButton>
      </Wrapper>
    </OuterWrapper>
  );
};

export default AnnouncementBanner;
