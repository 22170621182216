import Image from 'next/image';
import styled from 'styled-components';
import { AutoLink } from './Link';
import type { NavigationItem } from './Navbar';

type MenuBoxProps = {
  width: number
}

const MenuBox = styled.div<MenuBoxProps>`
  background: white;
  transition: width ${(props) => props.theme.transitions.short};
  width: ${(props) => `${props.width}px`};
  border: 1px solid var(--color-charcoal-v10);
  box-shadow: ${(props) => props.theme.shadows.hard};
  border-radius: 16px;
  padding: 40px 0;
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
`;

const MenuBoxColumn = styled.div`
  padding: 0 40px;
  &:not(:first-child) {
    border-left: 1px solid var(--color-charcoal-v20);
  }
`;

const MenuBoxItemTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  transition: color ${(props) => props.theme.transitions.short};
  color: var(--color-charcoal-v100);
`;

const MenuBoxItemDescription = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
  transition: color ${(props) => props.theme.transitions.short};
  color: var(--color-charcoal-v50);
`;

const MenuBoxItemIconWrapper = styled.div`
  margin-right: 15px;
  color: var(--color-bluescape-v100);
  svg, img {
    transition: transform ${(props) => props.theme.transitions.short};
  }
`;

const MenuBoxItem = styled.a`
  text-decoration: none;
  color: var(--color-charcoal-v50);
  display: block;
  padding: 15px;
  display: flex;
  border-radius: 8px;
  transition: background ${(props) => props.theme.transitions.short};
  &:hover {
    background: var(--color-bluescape-v05);
    ${MenuBoxItemIconWrapper} svg, ${MenuBoxItemIconWrapper} img {
      transform: scale(1.1);
    }
    ${MenuBoxItemTitle} {
      color: var(--color-bluescape-v100);
    }
    ${MenuBoxItemDescription} {
      color: var(--color-charcoal-v100);
    }
  }
  &:active {
    background: var(--color-bluescape-v10);
  }
  &.disabled {
    cursor: default;
    background: var(--color-charcoal-v05);
    ${MenuBoxItemTitle} {
      color: var(--color-charcoal-v60);
    }
    ${MenuBoxItemDescription} {
      color: var(--color-charcoal-v50);
    }
    ${MenuBoxItemIconWrapper} svg {
      color: var(--color-charcoal-v50);
      transform: scale(1);
    }
  }
`;

const MenuBoxColumnTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: var(--color-charcoal-v50);
`;

type GridProps = {
  height: number
};

const Grid = styled.div<GridProps>`
  margin-top: 20px;
  display: grid;
  grid-auto-columns: 280px 280px;
  row-gap: 12px;
  column-gap: 32px;
  grid-template-rows: repeat(${(props) => props.height}, 1fr);
  grid-auto-flow: column;
`;

const PlaceholderIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.41074 1.16116C9.73618 0.835718 10.2638 0.835718 10.5893 1.16116L18.8388 9.41073C19.1643 9.73617 19.1643 10.2638 18.8388 10.5892L10.5893 18.8388C10.2638 19.1643 9.73618 19.1643 9.41074 18.8388L1.16117 10.5892C0.835729 10.2638 0.835728 9.73617 1.16117 9.41073L9.41074 1.16116ZM10 2.92892L2.92893 9.99999L10 17.0711L17.0711 9.99999L10 2.92892Z" fill="var(--color-bluescape-v100)" />
  </svg>
);

const calculateMenuBoxWidth = ({ children, height }: Pick<NavigationItem, 'children'> & {height: number}) => (
  children.map((child) => {
    const numColumns = Math.ceil(child.children.length / height);
    return numColumns * 280 + (numColumns - 1) * 30 + 80;
  }).reduce((a, b) => a + b, 0)
);

export default ({ children }: NavigationItem) => {
  let height = Math.max(...children.map((child) => child.children.length));
  if (children.length === 1) {
    height = Math.min(children[0].children.length, 3);
  }
  return (
    <MenuBox width={calculateMenuBoxWidth({ children, height })}>
      {children.map((section) => (
        <MenuBoxColumn key={section.title}>
          <MenuBoxColumnTitle>
            {section.title}
          </MenuBoxColumnTitle>
          <Grid height={Math.min(section.children.length, height)}>
            {section.children.map((item) => (
              item.link && (
              <AutoLink as={MenuBoxItem} key={item.title} {...item.link}>
                <MenuBoxItemIconWrapper>
                  {item.icon ? (
                    <Image height={20} width={20} src={item.icon.url} alt={item.icon.description} />
                  ) : (
                    <PlaceholderIcon />
                  )}
                </MenuBoxItemIconWrapper>
                <div>
                  <MenuBoxItemTitle>{item.link?.title}</MenuBoxItemTitle>
                  <MenuBoxItemDescription>
                    {item.title}
                  </MenuBoxItemDescription>
                </div>
              </AutoLink>
              )
            ))}
          </Grid>
        </MenuBoxColumn>
      ))}
    </MenuBox>
  );
};
