/* eslint-disable @next/next/no-html-link-for-pages */
import { ComponentProps } from 'react';
import styled from 'styled-components';
import { CFLink } from '../../lib/types';
import { AutoButton } from '../components/Button';
import Link, { AutoLink } from '../components/Link';
import type Navbar from '../components/Navbar';
import { NavigationItem } from '../components/Navbar';

const Background = styled.footer`
  background: var(--color-bluescape-dark);
  color: var(--color-bluescape-v20);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 15px;
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    font-size: 14px;
  }

  &, * {
    box-sizing: border-box;
  }
`;
const Logo = styled.img`
  width: 150px;
  height: 25px;
  margin-top: -6px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const Container = styled.div`
  max-width: ${(props) => props.theme.layout.maxWidth};
  margin: 0 auto;
  padding: 64px 64px 48px;

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    padding: 42px 24px 0px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  /* Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    flex-direction: column;
    gap: unset;
    & > div {
      margin-bottom: 24px;
    }
  }

  /* Tablet + Desktop */
  @media (min-width: calc(${(props) => props.theme.breakpoints.medium} + 1px)) {
    .bottom_logo_terms {
      display: inherit;
    }
    .bottom_terms {
      display: none;
    }
  }
  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    .bottom_login_column {
      gap: 16px;
      border-bottom: 1px solid #338EFF80;
    }
    .bottom_logo_terms {
      display: none;
    }
    .bottom_terms {
      display: inherit;
    }
  }
`;

const LinkColumns = styled.div`
  column-count: 4;
  column-gap: 32px;
  flex: 1;

  /* Tablet+ */
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    column-count: 3;
  }

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    border-bottom: 1px solid #338EFF80;
    column-count: 2;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  flex-direction: column;
  break-inside: avoid-column;
  width: max-content;

  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.large}) {
    margin-bottom: 24px;
    overflow: hidden; /* fix for Firefox */
  }
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;
const SectionTitle = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 8px;
`;

const FooterLink = styled.a`
  display: block;
  color: var(--color-charcoal-v10);
  text-decoration: none;
  padding-bottom: 8px;
  &:hover {
    color: var(--color-charcoal-v20);
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  a, .link {
    display: inline-block;
  }
`;

const SocialContainer = styled.div`
  margin-bottom: 24px;
  white-space: nowrap;
  /* Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    margin-bottom: 16px;
  }
`;
const AddressContainer = styled.div`
  white-space: pre-wrap;

  /* Tablet + Desktop */
  @media (min-width: calc(${(props) => props.theme.breakpoints.medium} + 1px)) {
    margin-bottom: 24px;
  }
`;
const Social = styled.a.attrs({ rel: 'noopener' })`
  display: inline-block;
  background-color: white;
  width: 24px;
  height: 25px;
  margin-right: 8px;
  mask-size: cover;
  mask-repeat: no-repeat;

  &.twitter { mask-image: url('/images/social_icon_twitter.svg'); }
  &.facebook { mask-image: url('/images/social_icon_facebook.svg'); }
  &.linkedin { mask-image: url('/images/social_icon_linkedin.svg'); }
  &.instagram { mask-image: url('/images/social_icon_instagram.svg'); }
  &.youtube { mask-image: url('/images/social_icon_youtube.svg'); }

  &:last-child {
    margin-right: 0;
  }
  &:hover, &:focus {
    background-color: var(--color-charcoal-v20);
  }
`;

interface TermProps {
  className?: string;
  terms: CFLink[];
  copyrightSuffix: string;
}
const Terms = (props: TermProps) => (
  <Section className={props.className}>
    {props.terms.map((link) => (
      <AutoLink as={FooterLink} key={link.title} {...link} />
    ))}
    <p>
      ©
      {' '}
      {new Date().getFullYear()}
      {' '}
      {props.copyrightSuffix}
    </p>
  </Section>
);

const flattenChildren = (items: NavigationItem[]): NavigationItem[] => {
  const result: NavigationItem[] = [];
  items.forEach((item) => {
    if (item.children) {
      result.push(...flattenChildren(item.children));
    } else if (item.link) {
      result.push(item);
    }
  });
  return result;
};

const getLeaves = (parent: NavigationItem): {title: string, link: CFLink}[] => {
  if (parent.link) {
    return [{ title: 'Overview', link: parent.link }];
  }

  if (parent.children) {
    return flattenChildren(parent.children).map((child) => ({
      title: child.link?.title,
      link: child.link,
    })).filter((child) => child.link) as {title: string, link: CFLink}[];
  }

  return [];
};

const convertNavbarDataToFooter = (
  items: NavigationItem[],
): {[title: string]: {title: string, link: CFLink}[]} => {
  const footerData: {[title: string]: {title: string, link: CFLink}[]} = {};
  if (items) {
    items.forEach((item) => {
      if (item.children[0]?.children.length > 0) {
        item.children.forEach((child) => {
          footerData[child.title] = getLeaves(child);
        });
      } else {
        footerData[item.title] = getLeaves(item);
      }
    });
  }
  return footerData;
};

const getLinksFromNavbar = (items: NavigationItem[]): CFLink[] => {
  const links: CFLink[] = [];
  if (items) {
    items.forEach((item) => {
      if (item.link) {
        links.push(item.link);
      }
      if (item.children) {
        item.children.forEach((child) => {
          if (child.link) {
            links.push(child.link);
          }
        });
      }
    });
  }
  return links;
};

const Footer = ({
  address, termsLinks, items, secondaryItems, callToAction, copyrightSuffix,
}: ComponentProps<typeof Navbar>) => {
  const rawSections = convertNavbarDataToFooter(items || []);
  const secondarySections = getLinksFromNavbar(secondaryItems || []);
  return (
    <Background>
      <Container>
        <BottomContainer>
          <Column className="logo_column">
            <Link as={FooterLink} href="/">
              <Logo src="/images/logo-light.svg" alt="Bluescape logo" />
            </Link>
            <SocialContainer>
              <Social className="twitter" aria-label="Twitter" target="_blank" href="https://twitter.com/bluescaper" />
              <Social className="facebook" aria-label="Facebook" target="_blank" href="https://www.facebook.com/bluescaper/" />
              <Social className="linkedin" aria-label="Linkedin" target="_blank" href="https://www.linkedin.com/company/bluescaper/" />
              <Social className="instagram" aria-label="Instagram" target="_blank" href="https://www.instagram.com/bluescapers/" />
              <Social className="youtube" aria-label="Youtube" target="_blank" href="https://www.youtube.com/channel/UC5kiLquEedZ423BVUWv6swg" />
            </SocialContainer>
            <AddressContainer>
              {address}
            </AddressContainer>
            <Terms className="bottom_logo_terms" terms={termsLinks} copyrightSuffix={copyrightSuffix} />
          </Column>

          <LinkColumns>
            {Object.entries(rawSections).map(([title, links]) => (
              <Section key={title}>
                <SectionTitle>{title}</SectionTitle>
                {links.map((link, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <AutoLink key={i} as={FooterLink} {...{ ...link.link, title: link.title }} />
                ))}
              </Section>
            ))}
          </LinkColumns>

          <Column className="bottom_login_column">
            <ButtonWrapper className="theme-bluescape-dark">
              {callToAction && <AutoButton small secondary {...callToAction} />}
            </ButtonWrapper>
            <Section>
              {secondarySections.map((link) => (
                <AutoLink as={FooterLink} key={link.title} {...link} />
              ))}
            </Section>
          </Column>
          <Terms className="bottom_terms" terms={termsLinks} copyrightSuffix={copyrightSuffix} />
        </BottomContainer>
      </Container>
    </Background>
  );
};
export default Footer;
