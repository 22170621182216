import { useState } from 'react';
import styled from 'styled-components';
import { CFLink } from '../../lib/types';
import Button from './Button';
import { AutoLink } from './Link';
import type { NavigationItem } from './Navbar';

type Props = {
  items: Array<NavigationItem>
  secondaryItems?: Array<NavigationItem>
  callToAction?: CFLink
}

const Chevron = () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.293137C0.683417 -0.097387 1.31658 -0.097387 1.70711 0.293137L7 5.58603L12.2929 0.293137C12.6834 -0.097387 13.3166 -0.097387 13.7071 0.293137C14.0976 0.683662 14.0976 1.31683 13.7071 1.70735L7.70711 7.70735C7.31658 8.09788 6.68342 8.09788 6.29289 7.70735L0.292893 1.70735C-0.0976311 1.31683 -0.0976311 0.683662 0.292893 0.293137Z" fill="#2E363D" />
  </svg>
);

const PlaceholderIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.41074 1.16116C9.73618 0.835718 10.2638 0.835718 10.5893 1.16116L18.8388 9.41073C19.1643 9.73617 19.1643 10.2638 18.8388 10.5892L10.5893 18.8388C10.2638 19.1643 9.73618 19.1643 9.41074 18.8388L1.16117 10.5892C0.835729 10.2638 0.835728 9.73617 1.16117 9.41073L9.41074 1.16116ZM10 2.92892L2.92893 9.99999L10 17.0711L17.0711 9.99999L10 2.92892Z" fill="var(--color-bluescape-v100)" />
  </svg>
);

/* Expandable menu */

const ExpandableMenu = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 70px);
  z-index: 10000;
  position: fixed;
  left: 0;
  top: 70px;
  padding: 12px 24px 24px;
  background: white;
  overflow: auto;
`;

const ExpandableMenuSection = styled.div`
  border-top: 1px solid var(--color-charcoal-v10);
  padding: 24px 0;
  display: grid;
  gap: 24px;
`;

const CallToAction = styled.div`
  border-top: 1px solid var(--color-charcoal-v10);
  padding-top: 24px;
`;

const ExpandableMenuHeader = styled.button`
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  font-family: inherit;
  color: var(--theme-text-color);
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background: none;
  svg {
    transition: 0.15s;
  }
  &.expanded {
    color: var(--color-bluescape-v100);
  }
  &.expanded svg {
    transform: rotate(180deg);
  }
`;

/* Grid and grid items */

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const GridItemHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
`;

const GridItemLink = styled.a`
  text-decoration: none;
  color: var(--theme-text-color);
`;

const GridItem = styled.div`
  margin: 0 24px;
  display: flex;
  padding: 12px;
  border-radius: 8px;
  &:hover {
    background: var(--color-bluescape-v05);
    ${GridItemHeader} {
      color: var(--color-bluescape-v100);
    }
  }
  &:active {
    background: var(--color-bluescape-v10);
  }
`;

const GridItemDescription = styled.div`
  color: var(--color-charcoal-v50);
  font-size: 14px;
  line-height: 1.6;
`;

const GridItemIcon = styled.div`
  color: var(--color-bluescape-v100);
  margin-right: 12px;
`;

const Section = (props: NavigationItem) => {
  const { link } = props;
  return (
    <ExpandableMenuSection>
      {link && (
      <AutoLink {...link}>
        <ExpandableMenuHeader>
          {link.title}
        </ExpandableMenuHeader>
      </AutoLink>
      )}
    </ExpandableMenuSection>
  );
};

const ExpandableSection = ({ title, children }: NavigationItem) => {
  const [expanded, setExpanded] = useState(false);
  const subItems = children.flatMap((child) => child.children);
  return (
    <ExpandableMenuSection>
      <ExpandableMenuHeader
        className={expanded ? 'expanded' : ''}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {title}
        {subItems && subItems.length > 0 && (
          <div>
            <Chevron />
          </div>
        )}
      </ExpandableMenuHeader>
      {expanded && subItems && subItems.length > 0 && (
        <Grid>
          {subItems.map((item, i) => (
            item.link && (
              // eslint-disable-next-line react/no-array-index-key
              <AutoLink as={GridItemLink} key={i} {...item.link}>
                <GridItem>
                  <GridItemIcon>
                    {item.icon ? (
                      <img
                        height={20}
                        width={20}
                        src={item.icon.url}
                        alt={item.icon.description}
                      />
                    ) : (
                      <PlaceholderIcon />
                    )}
                  </GridItemIcon>
                  <div>
                    <GridItemHeader>{item.link?.title}</GridItemHeader>
                    <GridItemDescription>
                      {item.title}
                    </GridItemDescription>
                  </div>
                </GridItem>
              </AutoLink>
            )))}
        </Grid>
      )}
    </ExpandableMenuSection>
  );
};

export default ({ items, callToAction }: Props) => (
  <div className="theme-bluescape-pale">
    <ExpandableMenu>
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        item.children.length ? <ExpandableSection key={i} {...item} /> : <Section {...item} />
      ))}
      {callToAction && (
        <CallToAction>
          <Button small>{callToAction.title}</Button>
        </CallToAction>
      )}
    </ExpandableMenu>
  </div>
);
