import { ComponentProps } from 'react';
import styled from 'styled-components';
import Link from './Link';

export const ArrowIcon = () => (
  <svg aria-hidden width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.37385 0.450852C5.98333 0.0603279 5.35017 0.0603279 4.95964 0.450852C4.56912 0.841376 4.56912 1.47454 4.95964 1.86507L7.91922 4.82465H1C0.447715 4.82465 0 5.27236 0 5.82465C0 6.37693 0.447715 6.82465 1 6.82465H7.91918L4.95964 9.78419C4.56912 10.1747 4.56912 10.8079 4.95964 11.1984C5.35017 11.5889 5.98333 11.5889 6.37385 11.1984L11.0405 6.53173C11.1632 6.40904 11.2474 6.26239 11.293 6.10684C11.3046 6.06706 11.3139 6.02624 11.3206 5.98457C11.3381 5.87622 11.3377 5.76555 11.3194 5.65731C11.3133 5.62127 11.3052 5.58549 11.2952 5.55016C11.2501 5.39174 11.1653 5.24225 11.0405 5.11752L6.37385 0.450852Z" fill="currentColor" />
  </svg>
);

const ArrowLink = styled.a<{dark?: boolean}>`
  display: flex;
  align-items: center;
  font-weight: 600;
  text-decoration: none;
  color: var(--color-bluescape-v100);
  svg {
    margin-left: 8px;
  }
  &:hover {
    color: var(--color-bluescape-v60);
  }

  ${({ dark }) => dark && `
    color: var(--theme-detail-color);
    &:hover {
      color: white;
    }
  `}
`;

const ArrowLinkDiv = styled(ArrowLink).attrs({ as: 'div' })``;

type Props = ComponentProps<typeof ArrowLink> & {
  href: string
  title: string
  isDiv?: boolean
  newTab?: boolean
  dark?: boolean
  children?: React.ReactNode
}

export default (props: Props) => (
  <Link {...props} as={props.isDiv ? ArrowLinkDiv : ArrowLink}>
    {props.title || props.children}
    <ArrowIcon />
  </Link>
);
